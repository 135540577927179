import React, { useEffect } from 'react'

// props.url: string | ({ queryParams: URLSearchParams }) => string
//   URL to redirect user to on component mount. If a function is supplied, the
//   return value of the function is used as the redirect URL.
const Redirect = function({ url }) {
  useEffect(() => {
    const evaluatedURL = typeof(url) === 'function'
      ? url({ queryParams: new URLSearchParams(window.location.search) })
      : url

    window.location.replace(evaluatedURL)
  })

  return (
    <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
      <span style={{opacity: 0.5}}>redirecting...</span>
    </div>
  )
}

export default Redirect